<template>
    <div class=" ">
      <div class="container-fluid g-0">
        <div class="row w-100 g-0">
          <div class="col-lg-5 order-lg-1 order-2">
            <div class="intro-wrapper h-100">
              <router-link class="navbar-brand nav-logo  d-lg-block d-none" to="/"><img src = "../../public/images/logo.svg" alt="Swift"/></router-link>
              <div class=" animate__animated animate__fadeIn animate__delay-5ms">
                <h1 class="pl-0 pb-2">404<br>Page Not Found</h1>
              </div>
            </div>
          </div>
          <div class="col-lg-7 order-lg-2 order-1">
            <div class="edge-banner">
              <HeaderTag />
              <img src="../../public/images/twist-our-edge-banner.jpg" class="mw-100" />
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import HeaderTag from './Header.vue'
    export default {
        name: "PageNotFound",
        components: HeaderTag,
    }
</script>