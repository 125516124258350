import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import NotFound from '../views/404NotFound.vue';


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/we-are',
    name: 'We Are',
    component: () => import('../views/WeAre.vue')
  },
  {
    path: '/our-solutions',
    name: 'Our Solutions',
    component: () => import('../views/Solutions.vue')
  },
  {
    path: '/why-twist',
    name: 'Why Twist',
    component: () => import('../views/WhyTwist.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/disclaimer',
    name: 'Disclaimer',
    component: () => import('../views/Disclaimer.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'Privacy policy',
    component: () => import('../views/PrivacyPolicy.vue'),
  },
  {
    path: '/cookie-policy',
    name: 'Cookie policy',
    component: () => import('../views/CookiePolicy.vue'),
  },
  {
    path: '/intellectual-property',
    name: 'Intellectual property',
    component: () => import('../views/IntellectualProperty.vue'),
  },
  {
    path: '/social-media-disclaimer',
    name: 'Social media disclaimer',
    component: () => import('../views/SocialMediaDisclaimer.vue'),
  },
  {
    path: '/policies',
    name: 'Policies',
    component: () => import('../views/Policies.vue'),
  },
  {
    path: '/:catchAll(.*)',
    name: 'PageNotFound',
    component: NotFound,
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,

  scrollBehavior (to, from, savedPosition){
    return savedPosition || { top:0 }
  }
})

router.beforeEach((to, from, next) => {
  document.title = `Twist - ` + to.name;
  next()
})

export default router
