<template>
    <div>
        <NotFoundTag />
    </div>
</template>

<script>
import NotFoundTag from '@/components/404NotFound.vue'

export default {
    name: 'PageNotFound',
    components: NotFoundTag,
}
</script>