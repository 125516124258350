<template>
  <!-- <HeaderTag /> -->
  <router-view/>
  <BackToTop />
  <FooterTag />

</template>

<script>
  // import HeaderTag from './components/Header.vue';
  import BackToTop from './components/BackToTop.vue';
  import FooterTag from './components/Footer.vue';
  

  export default {
    name: 'App',
    components: {
      FooterTag, BackToTop, 
    }
  }

</script>

<style>
#app {
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000;
  position: relative;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #000;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
