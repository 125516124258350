<template>
  <div class="site-header ">
      <nav class="navbar navbar-expand-lg navbar-light bg-light container-fluid sticky-top switch-bg-orange" id="navbar">
        <div class="container d-lg-none d-block">
          <div class="row w-100 justify-content-between g-0">
            <div class="col-2">
              <router-link class="navbar-brand nav-logo resp-logo" to="/">
                <img src = "../../public/images/icons/swift-list.png" alt="Swift" width="50px"/>
              </router-link>
            </div>
            <div class="col-4 align-self-center">
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
            </div>
          </div>
        </div>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0 m-auto">
            <li class="nav-item align-self-center">
              <router-link class="nav-link active" aria-current="page" to="/">Home</router-link>
            </li>
            <li class="nav-item align-self-center">
              <router-link class="nav-link" aria-current="page" to="/we-are">About</router-link>
            </li>
            <li class="nav-item align-self-center">
              <router-link class="nav-link" aria-current="page" to="/our-solutions">Our solutions</router-link>
            </li>
            <li class="nav-item align-self-center">
              <router-link class="nav-link" aria-current="page" to="/why-twist"> Why Twist?</router-link>
            </li>
            <li class="nav-item align-self-center">
              <router-link class="nav-link" aria-current="page" to="/contact">Contact Us</router-link>
            </li>
          </ul>
        </div>
      </nav>
  </div>
</template>

<script>

export default {
  data(){
    return{
      banner: 'header-banner',
    }
  }, 

  name: 'HeaderSection',
}

  // When the user scrolls down 80px from the top of the document, resize the navbar's padding and the logo's font size
  window.onscroll = function() {scrollFunction()};
 

  function scrollFunction() {
    let nv = document.getElementById("navbar");
    
    if (document.body.scrollTop > 30 || document.documentElement.scrollTop > 30) {
      nv.style.padding = "15px 0px";
    } else {
      nv.style.padding = "20px 0px";
    }
  }
</script>

<style >
  .navbar{
    padding: 45px 0 30px 0; 
    transition: 0.4s;
    box-shadow: 2px 0px 8px grey;
  }
  
  .nav-logo img{
    width: 116px;
    height: 119px;
  }

  .intro-wrapper{
    background: #000;
    text-align: left;
    padding: 37px 44px;
  }

  .intro-wrapper div h1{
    font-family: 'Montserrat Bold';
    padding: 80px 30px 10px 0px;
    font-weight: 600;
  }

  .navbar-nav li.nav-item{margin-right: 50px}

  nav a.router-link-exact-active {
    position: relative;
    color: #e9812e;
}

nav a.router-link-exact-active:before {
    content: "";
    position: absolute;
    background: #e9812e;
    width: 12px;
    height: 12px;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
}

.header-banner{
  background: url('../../public/images/header-banner.jpg') no-repeat;
  background-size: cover;
  min-height: 750px;
  background-position: 0 70px;
}

.navbar-expand-lg .navbar-nav .nav-link:hover{
  color: #e9812e;
}



@media screen and (max-width: 1200px){
  .intro-wrapper div{padding: 25px 30px 0;}
  .intro-wrapper div h1{font-size: 2em; padding-right: 0; padding-top: 0;}
}
@media screen and (max-width: 991px){
  .nav-logo img{height: 65px;}
  .resp-logo img{height: 65px; width: 65px}
  .navbar-toggler{border: none;}
  button:focus{box-shadow: none}
  .resp-logo::before{display: none;}
  nav a.router-link-exact-active:before{display: none;}
  .navbar{padding: 10px 0 15px 0;}
  .navbar-toggler{float:right}
  .navbar-nav{text-align: right;}
  .navbar-nav li.nav-item{width: 100%;}
}

</style>