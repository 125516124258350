<template>
    <div class="container" >
        <div class="row" >
            <div ref="scrollTopButton" class="invisible sticky w-full flex justify-end bottom-0 pr-5 lg:pr-16 transition" >
                <div class="scrollTopBtn transition text-end">
                    <button @click="scrollToTop"></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineComponent } from "vue";
 
    export default defineComponent({
        mounted() {
            window.addEventListener("scroll", this.handleScroll);
        },
 
        beforeUnmount() {
            window.removeEventListener("scroll", this.handleScroll);
        },
 
        methods: {
            handleScroll() {
                const scrollBtn = this.$refs.scrollTopButton;
 
                if (window.scrollY > 0) {
                    scrollBtn.classList.remove("invisible");
                } else {
                    scrollBtn.classList.add("invisible");
                }
            },
            scrollToTop() {
                window.scrollTo({ top: 0, behavior: "smooth" });
            },
        },
    }); 

</script>

<style scoped>
.scrollTopBtn{
    position: relative;
}

.scrollTopBtn button{
    position: absolute;
    bottom: 0; 
    right: 0; 
    height: 50px; 
    width: 50px; 
    border-radius: 50%;
    border: 2px solid #df762a;
    background: #fff;
}

.scrollTopBtn button:before{
    content: ""; 
    background-image: url("../assets/chevron-up.svg"); 
    background-repeat: no-repeat; 
    position: absolute; 
    height: 16px; 
    width: 16px; 
    border-radius: 50%; 
    left: 50%; 
    top: 50%; 
    transform: translate(-50%, -50%);
}
</style>
