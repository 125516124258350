<template>
  <div class="footer-bg mt-5">
    <div class="footer-inner-wrapper mt-5">
      <div class="">
        <div class="container">
          <div class="row my-5 text-start">
            <div class="col-lg-4 pe-5 mb-lg-0 mb-4">
              <img src = "../../public/images/footer-logo.svg" alt="Swift logo" class="mb-3" width="60"/>
              <p>A specialist BPO provider with a global hub based in Johannesburg and delivery centres in Spain, Mauritius and Namibia.</p>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
              <h5 class="switch-orange"><strong>Quick links</strong></h5>
              <ul class="nav flex-column mt-5">
                <li class="nav-item mb-2"><a href="/" class="nav-link p-0 foot-link">Home</a></li>
                <li class="nav-item mb-2"><a href="/we-are" class="nav-link p-0 foot-link">About</a></li>
                <li class="nav-item mb-2"><a href="/our-solutions" class="nav-link p-0 foot-link">Our solutions</a></li>
                <li class="nav-item mb-2"><a href="/why-twist" class="nav-link p-0 foot-link">Why Twist?</a></li>
                <li class="nav-item mb-2"><a href="/contact" class="nav-link p-0 foot-link">Contact</a></li>
              </ul>
            </div>
            
            <div class="col-lg-5 col-md-6 col-sm-6 text-lg-end text-md-end text-sm-end mt-lg-0 mt-md-0 mt-sm-0 mt-4">
              <h5 class="switch-orange"><strong>Let's get in touch</strong></h5>
              <p class = "mb-2 mt-5 text-lg-end text-md-end text-sm-end">(+27) 11 449 7300<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-telephone-fill ms-2" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
            </svg></p>
              <p class = "mb-2 text-lg-end text-md-end text-sm-end">info@twistbpo.com<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-envelope ms-2" viewBox="0 0 16 16">
              <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
            </svg></p>
              <p class="text-lg-end text-md-end text-sm-end"><a href="https://www.google.com/maps?ll=-26.093914,28.000073&z=13&t=m&hl=en-ZA&gl=US&mapclient=embed&q=308+Kent+Ave+Ferndale+Randburg+2194" target="_blank" class="switch-white">Open Map</a><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-geo-alt ms-2" viewBox="0 0 16 16">
              <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
              <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
            </svg></p>
            <p class="text-lg-end text-md-end text-sm-end">
              <a href="https://www.linkedin.com/company/twist-bpo/" class="social-link" target="_blank"><i class="bi bi-linkedin"></i></a>
              <a href="https://business.facebook.com/TwistBPO/"  class="social-link" target="_blank"><i class="bi bi-facebook"></i></a>
            </p>
            </div>

             <!-- <div class="col-2">
              <h5>&nbsp;</h5>
              <ul class="nav flex-column mt-5">
                <li class="nav-item mb-2"><router-link to="/disclaimer" class="nav-link p-0 text-muted">Disclaimer</router-link></li>
                <li class="nav-item mb-2"><a href="/social-media-disclaimer" class="nav-link p-0 text-muted">Social Media Disclaimer</a></li>
                <li class="nav-item mb-2"><router-link to="/cookie-policy" class="nav-link p-0 text-muted">Cookie Policy</router-link></li>
                <li class="nav-item mb-2"><router-link to="/privacy-policy" class="nav-link p-0 text-muted">Privacy Policy</router-link></li>
                <li class="nav-item mb-2"><router-link to="/intellectual-property" class="nav-link p-0 text-muted">Intellectual Property</router-link></li>
              </ul>
            </div> -->
         <!-- </div> -->

          <!-- <div class="offset-lg-2 offset-md-2 col-lg-4 col-md-4">
            <h5><strong>Newsletter</strong></h5>
              <form class="news-form mt-4" @submit="sendNewletter()" action="" method="post">
                <p v-if="errors.length">Please correct email
                  <ul>
                    <li v-for="error in errors" :key="error.index"></li>
                  </ul>
                </p>
                <input type="text" placeholder="Enter you email" class="newsletter" id="email" name="email"/>
                <button value="" type="submit" class="newsletter-btn"></button>
              </form>
            </div> -->
          </div>

          <div class="d-lg-flex d-md-flex d-sm-flex justify-content-between py-4 my-4 border-top mb-0">
            <p class="copy-txt">Copyright 2023 © Twist. All rights reserved. | <a href="/policies" class="nav-link p-0 d-inline">Privacy Policies and Disclaimer</a></p>
             <ul class="list-unstyled d-flex">
                <li class="ms-3">
                  <a href ="https://www.plp.co.za/" target="_blank" class="switch-white no-line">A   <img src="../assets/plp-logo.svg" class="mx-2 my-auto" alt="PLP" width="100px"> Company</a>
                </li>
              </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    data(){
      return{
        errors: [],
        email: null,
      }
    },

    methods:{
      sendNewletter: function (e) {
        if(this.email){
          return true;
        }

        this.errors = [];

        if(!this.email){
          this.errors.push('Email required.');
        }

        e.preventDefault();
      }
    },

    name: 'FooterSection',

    
  }
</script>

<style scoped>
  .footer-logo{
    width: 50px; height: 50px;
  }

  .footer-bg{
    background: url('../../public/images/footer-texture.png') no-repeat;
    height: 100%;  
    background-size: cover;
    padding-top: 50px;
    color: #FFF;
  }

  .footer-inner-wrapper{
    background: #28292d;
    opacity: 0.9;
    padding-top: 30px;
  }

  .newsletter{
    background: transparent;
    border: none;
    padding-right: 10px;
    color: #fff;
  }

  .news-form{
    position: relative;
    overflow: hidden;
  }

  .news-form:before{
    content: '';
    position:absolute;
    background: #FFF;
    width: 55%;
    height: 1px;
    bottom: 0;
  }

  .footer-inner-wrapper .nav-item a{ color:#FFF; font-size: 20px;}
  .footer-inner-wrapper p{ color:#FFF; font-size: 20px;}
  .newsletter-btn{
    border: none;
    background: transparent;
    position: relative;
  }
  .newsletter-btn:before{
    content: '';
    position: absolute;
    background: url('../../public/images/icons/arrow-right.png') no-repeat;
    top: -15px;
    right: -680px;
    width: 700px;
    height: 226px;
    background-size: 7%;
  }

  input.newsletter:focus-visible{
    outline: none !important;
  }
  .list-unstyled li a svg{ transition: 0.3s ease all;}
  .list-unstyled li a:hover svg{
    fill: #df762a;
    transition: 0.3s ease all;
  }
  
  .no-line{text-decoration: none;}
  .copy-txt{font-size: 16px !important;}
  .copy-txt a:hover, .nav-item .foot-link:hover{color: #df762a}
  .social-link{color: #fff; margin-right: 5px; transition: 0.3s ease-in all;}
  .social-link:hover{color: #df762a; transition: 0.3s ease-in all;}
 
</style>

